<template>
    <div class="animated fadeIn mt-sm-3 pt-sm-3 pb-sm-0 px-3 mt-3 pt-3 pb-0 px-3 px-1">
        <b-card no-body class="card-material">
            <TdcV0 v-if="uiVersion === 'tdc_v0'"/>
            <TdcV1 v-else-if="uiVersion === 'tdc_v1'"/>
            <TdcV2 v-else-if="uiVersion === 'tdc_v2'"/>
            <TdcV3 v-else-if="uiVersion === 'tdc_v3'"/>
            <PleionV1 v-else-if="uiVersion === 'pln_v1'"/>
            <PleionV1_5 v-else-if="uiVersion === 'pln_v1_5'"/>
        </b-card>
    </div>
</template>

<script>
    import store from '@/store';
    import {mapGetters} from 'vuex';

    const TdcV0 = () => import('@/components/virtual-device/edit/tdc/v0/TdcV0');
    const TdcV1 = () => import('@/components/virtual-device/edit/tdc/v1/TdcV1');
    const TdcV2 = () => import('@/components/virtual-device/edit/tdc/v2/TdcV2');
    const TdcV3 = () => import('@/components/virtual-device/edit/tdc/v3/TdcV3');
    const PleionV1 = () => import('@/components/virtual-device/edit/pleion/v1/PleionV1');
    const PleionV1_5 = () => import('@/components/virtual-device/edit/pleion/v1_5/PleionV1_5');

    export default {
        name: "VirtualDeviceEdit",

        components: {
            TdcV0,
            TdcV1,
            TdcV2,
            TdcV3,
            PleionV1,
            PleionV1_5,
        },

        computed: {
            ...mapGetters({
                getUiVersion: 'virtualDevice/uiVersion',
            }),

            uiVersion: function () {
                return this.getUiVersion(this.$route.params.id);
            }
        },

        async beforeRouteEnter(to, from, next) {
            await store.dispatch('user/fetchUserSettings');
            await store.dispatch('virtualDevice/fetchEditViewData', to.params.id);
            next();
        },

        destroyed() {
            //Remove listener after leaving parameters view
            this.$root.$off('refresh-sliders');
        }
    }
</script>

<style scoped>
</style>
























